@import "../../themes.scss";

.drawer-menu {
  .MuiDrawer-paper {
    background-color: #fff;
    // background-image: linear-gradient(to bottom, #dfe7f2, #e3eaf3, #e8eef4, #edf1f5, #f2f4f6);
    box-shadow: rgba(19, 105, 233, 0.15) 1.95px 1.95px 2.6px;
  }

  .image-container {
    width: 180px;
    height: 180px;
    border-radius: 50% !important;

    .logo {
      width: 100%;
      height: auto;
    }
  }

  .list-menu {
    .list-item-active {
      min-width: 50px !important;
      height: 50px;

      .list-item-button {
        .list-item-icon {
          justify-content: center;
          color: $primary-color;
          height: 100%;
          min-width: 50px !important;

          .icon {
            width: 22px;
            height: 22px;
            // margin-top: 6px;
            color: $primary-color;
          }
        }

        .list-item-text {
          color: $primary-color;
          font-size: $font-size-subtitle;
          padding-top: 2px;
          font-family: $font-title;
          font-weight: bold;
        }
      }

    }

    .list-item {
      min-width: 50px !important;
      height: 50px;

      .list-item-button {
        .list-item-icon {
          justify-content: center;
          height: 100%;
          min-width: 50px !important;

          .icon {
            width: 22px;
            height: 22px;
            // margin-top: 6px;
          }
        }

        .list-item-text {
          font-size: $font-size-subtitle;
          font-weight: bold;
          padding-top: 2px;
          font-family: $font-title;
          color: $gray-font-color;
        }
      }
    }
  }
}
